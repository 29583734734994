





































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { Notification } from 'element-ui'

import { Card, GiftCard } from '@/models/index'

@Component({
  name: 'CardsList',
})
export default class extends Vue {
  private cards: Card[] = []
  private allCardsLoaded = false
  private cardsLoading = false
  private listQuery = {
    page: 1,
    limit: 20,
    email: undefined,
    programId: undefined,
    requestId: undefined,
    code: undefined,
    giftCardId: undefined
  }

  private search = ''
  private dateRange = null
  private editDistributeAtArray = Array(20)

  created() {
    this.getCards()
  }

  private async getCards() {
    this.cardsLoading = true

    const { data } = await Card
      .includes([
        'giftCard',
        'customer',
        { bulkDelivery: { order: 'wholesaler' } },
        { delivery: { order: 'buyer' }},
        'customerOrder',
        'pullRequest'
      ])
      .select({
        giftCards: ['name', 'logoSquare'], customers: ['firstname', 'lastname'],
        deliveries: ['id'], orders: ['number'], customerOrders: ['number'],
        wholesalers: ['name'], buyers: ['name']
      })
      .order({ createdAt: 'desc' })
      .where({
        code: { prefix: this.listQuery.code },
        requestId: { prefix: this.listQuery.requestId },
        giftCardId: this.listQuery.giftCardId,
        createdAt_gte: this.dateRange?.[0],
        createdAt_lte: this.dateRange?.[1]
      })
      .per(this.listQuery.limit)
      .page(this.listQuery.page)
      .all()

    this.cards.push(...data)
    if ((this.cards.length > 1 && data.length === 0) || this.cards.length < 20) this.allCardsLoaded = true
    this.cardsLoading = false
  }

  private async getGiftCardSuggestions(queryString: string, cb: any) {
    if (!queryString) cb([])

    const { data } = await GiftCard
      .where({ name: { prefix: queryString } })
      .all()

    const dataFormatted = data.map(e => {
      return { value: e.name, id: e.id}
    })

    cb(dataFormatted)
}

  private handleFilter() {
    this.listQuery.page = 1
    this.cards = []
    this.getCards()
  }

  private handleAction(command: any, card: Card) {
    if (command === 'undistribute') card.undistribute()
    else if (command === 'revoke') card.revoke()
    else if ( command === 'refresh') card.refresh()
  }

  private handleSelectGiftCard(giftCard: any) {
    this.listQuery.giftCardId = giftCard.id
    this.handleFilter()
  }

  private clearGiftCardFilter() {
    this.listQuery.giftCardId = undefined
    this.handleFilter()
  }

  private formatDate(date: Date) {
    if (!date) return undefined

    return this.$moment(date).format('L à LT')
  }

  private editDistributeAt(index: number) {
    const inverse = !this.editDistributeAtArray[index]
    this.editDistributeAtArray.splice(index, 1, inverse)
  }

  private async save(card: Card, index: number) {
    await card.save()

    this.editDistributeAtArray.splice(index, 1, false)
    Notification({
      title: 'Card distributd',
      message: 'Card has been distributed',
      type: 'success',
      duration: 2000
    })
  }
}
