








































































































































import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'

import { Customer, Program } from '@/models/index'

@Component({
  name: 'CustomersTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: Customer[] = []
  private total = 0
  private listLoading = true
  private listQuery = {
    page: 1,
    limit: 20,
    firstname: undefined,
    lastname: undefined,
    email: undefined,
    businessEmail: undefined,
    programId: undefined,
    locked: undefined
  }

  private programs: Program[] = []
  private programsLoading = false

  created() {
    this.getList()
    this.getPrograms()
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } = await Customer
      .includes(['program', 'advertiser'])
      .where({
        firstname: { match: this.listQuery.firstname },
        lastname: { match: this.listQuery.lastname },
        email: { match: this.listQuery.email },
        businessEmail: { match: this.listQuery.businessEmail },
        programId: this.listQuery.programId,
        locked: this.listQuery.locked
      })
      .order({ createdAt: 'desc' })
      .per(this.listQuery.limit)
      .page(this.listQuery.page)
      .stats({ total: 'count' })
      .all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private async getPrograms() {
    this.programsLoading = true
    const { data } = await Program
      .page(1)
      .per(999)
      .all()

    this.programs = data
    this.programsLoading = false
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private async handleDelete(data: Customer, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
    this.list.splice(index, 1)
    this.total -= 1
  }
}
