

























































import { Component, Vue } from 'vue-property-decorator'
import Pagination from '@/components/Pagination/index.vue'

import { Category } from '@/models/index'

@Component({
  name: 'CategoriesTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: Category[] = []
  private total = 0
  private listLoading = true
  private listQuery = {
    page: 1,
    limit: 20,
    name: undefined
  }

  created() {
    this.getList()
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } = await Category.stats({ total: 'count' }).all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private async handleDelete(data: Category, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
    this.list.splice(index, 1)
    this.total -= 1
  }
}
