var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table',{key:_vm.tableKey,staticStyle:{"width":"100%"},attrs:{"data":_vm.cards,"fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"Sku code","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.sku.code))])]}}])}),_c('el-table-column',{attrs:{"label":"Valeur faciale","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(_vm.$n(row.sku.faceValue, 'currency')))])]}}])}),_c('el-table-column',{attrs:{"label":"Livré le","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm._f("moment")(row.distributedAt,'L à LT'),"placement":"top-start"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.distributedAt,'L'))+" "),_c('i',{staticClass:"el-icon-info info-icon"})])])]}}])}),_c('el-table-column',{attrs:{"label":"Code","width":"180"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.code))])]}}])}),_c('el-table-column',{attrs:{"label":"Expiration","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm._f("moment")(row.expireAt,'L à LT'),"placement":"top-start"}},[_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(row.expireAt,'L'))+" "),_c('i',{staticClass:"el-icon-info info-icon"})])])]}}])}),_c('el-table-column',{attrs:{"label":"Actions","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{on:{"click":function($event){return _vm.$emit('dissociate', row)}}},[_vm._v("Dissocier")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }