






































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Advertiser, Customer } from '@/models/index'
import { Form } from 'element-ui'

@Component({
  name: 'CustomersTable',
  components: {
  }
})
export default class extends Vue {
  @Prop({ required: true }) private parentAdvertiser!: Advertiser

  private customersList: Customer[] = []

  private customersListLoading = false

  created() {
    this.getCustomersList()
  }

  private tempData = new Customer({
    subdomain: this.parentAdvertiser.subdomain
  })

  private rules = {
    amount: [{ required: true, message: 'amount is required', trigger: 'blur' }],
    requirement: [{ required: true, message: 'requirement is required', trigger: 'blur' }]
  }

  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private async getCustomersList() {
    this.customersListLoading = true
    const { data } =
      await Customer
        .where({ subdomain: this.parentAdvertiser.subdomain })
        .all()

    this.customersList = data
    this.customersListLoading = false
  }

  private resetTempData() {
    this.tempData = new Customer({
      subdomain: this.parentAdvertiser.subdomain
    })
  }

  private handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private handleUpdate(row: any) {
    this.tempData = row
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async handleDelete(data: Customer, index: number) {
    await data.destroy()

    this.customersList.splice(index, 1)
    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
  }

  private createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: 'advertiser.id' })

        if (this.dialogStatus === 'create') {
          this.customersList.push(data)
          this.$notify({
            title: 'Customer Created',
            message: 'Customer successfully created',
            type: 'success',
            duration: 2000
          })
        } else {
          this.$emit('update', data)
          this.$notify({
            title: 'Customer Updated',
            message: 'Customer successfully updated',
            type: 'success',
            duration: 2000
          })
        }
      }
      this.dialogFormVisible = false
    })
  }
}

