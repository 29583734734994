



























































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { Advertiser, Buyer } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'AdvertiserEdit',
  components: {
    CustomersTable: () => import('./components/customers-table.vue')
  }
})
export default class extends Vue {
  private advertiser: Advertiser = new Advertiser({
    buyer: new Buyer({ id: '' })
  })

  private buyerList: Buyer[] = []

  private advertiserLoading = false
  private buyerListLoading = false

  private rules = {
    name: [{ required: true, message: 'Name is required', trigger: 'blur' }],
    subdomain: [{ required: true, message: 'Subodmain is required', trigger: 'blur' }]
  }

  created() {
    if (!this.isCreate) this.getAdvertiser()
    this.getBuyerList()
  }

  get isCreate() {
    return this.$route.name === 'AdvertiserCreate'
  }

  get loading() {
    return this.advertiserLoading || this.buyerListLoading
  }

  private async getAdvertiser() {
    this.advertiserLoading = true
    const { data } = await Advertiser.includes('buyer').find(this.$route.params.id)

    this.advertiser = data
    this.advertiserLoading = false
  }

  private async getBuyerList() {
    this.buyerListLoading = true
    const { data } = await Buyer.page(1).per(999).all()

    this.buyerList = data
    if (this.isCreate) this.advertiser.buyer = data[0].dup()
    this.buyerListLoading = false
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        const data = this.advertiser

        await data.save({ with: 'buyer.id' })

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          if (this.isCreate) {
            this.$notify({
              title: 'Advertiser created',
              message: 'Advertiser successfully created',
              type: 'success',
              duration: 2000
            })
            this.$router.replace({ name: 'AdvertiserEdit', params: { id: data.id } })
          } else {
            this.$notify({
              title: 'Advertiser updated',
              message: 'Advertiser successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        }
      }
    })
  }

  private printErrors(errors: any) {
    const e: (IValidationError<Advertiser> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }
}
