























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Card, Customer, GiftCard, Delivery, Sku } from '@/models/index'
import { Form } from 'element-ui'

import Pagination from '@/components/Pagination/index.vue'

@Component({
  name: 'CardsTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  @Prop({ required: true }) private parentCustomer!: Customer

  private cards: Card[] = []
  private cardsLoading = false
  private cardsTotal = 0
  private cardsQueryParams = {
    page: 1,
    limit: 10,
    giftCardId: null
  }

  private availableCards: Card[] = []
  private availableCardsLoading = false
  private availableCardsTotal = 0
  private availableCardsQueryParams = {
    page: 1,
    limit: 10,
    giftCardId: null
  }

  private giftCards: GiftCard[] = []
  private giftCardsLoading = false

  private deliveries: Delivery[] = []

  private tempData = new Card({
    customer: new Customer({ id: this.parentCustomer.id }),
    giftCard: new GiftCard({ id: '' }),
    sku: new Sku({ id: '' }),
    delivery: new Delivery({ id: '' })
  })

  private createDialogFormVisible = false

  created() {
    this.getCards()
    this.getAvailableCards()
    this.getGiftCards()
    this.getDeliveries()
  }

  private async getCards() {
    this.cardsLoading = true
    const { data, meta } = await Card
      .includes(['giftCard', 'sku', 'customer'])
      .where({ customerId: this.parentCustomer.id })
      .where({ giftCardId: this.cardsQueryParams.giftCardId })
      .page(this.cardsQueryParams.page)
      .per(this.cardsQueryParams.limit)
      .stats({ total: 'count' })
      .all()

    this.cards = data
    this.cardsTotal = meta.stats.total.count
    this.cardsLoading = false
  }

  private async getAvailableCards() {
    this.availableCardsLoading = true
    const { data, meta } = await Card
      .includes(['giftCard', 'customer'])
      .where({ customerId: 'null' })
      .where({ giftCardId: this.availableCardsQueryParams.giftCardId })
      .where({ distributedAt: 'null' })
      .page(this.availableCardsQueryParams.page)
      .per(this.availableCardsQueryParams.limit)
      .stats({ total: 'count' })
      .all()

    this.availableCards = data
    this.availableCardsTotal = meta.stats.total.count
    this.availableCardsLoading = false
  }

  private async getGiftCards() {
    this.giftCardsLoading = true
    const { data } = await GiftCard
      .includes('skus')
      .page(1)
      .per(999)
      .all()

    this.giftCards = data
    this.giftCardsLoading = false
  }

  private async getDeliveries() {
    const { data } = await Delivery
      .page(1)
      .per(999)
      .all()

    this.deliveries = data
  }

  private resetTempData() {
    this.tempData = new Card({
      customer: this.parentCustomer.dup(),
      giftCard: this.giftCards[0].dup(),
      delivery: this.deliveries[0].dup()
    })

    this.tempData.sku = this.tempData.giftCard.skus.length > 0 ? this.tempData.giftCard.skus[0].dup() : new Sku({ id: '' })
  }

  private handleAdd() {
    this.resetTempData()
    this.createDialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async dissociate(data: Card, index: number) {
    data.customer.isMarkedForDisassociation = true
    await data.save({ with: 'customer.id' })

    this.cards.splice(index, 1)
    this.availableCards.push(data)
    this.$notify({
      title: 'Success',
      message: 'Dissociate Successfully',
      type: 'success',
      duration: 2000
    })
  }

  private async associate(data: Card, index: number) {
    data.customer = this.parentCustomer
    await data.save({ with: 'customer.id' })

    this.availableCards.splice(index, 1)
    this.cards.push(data)
    this.$notify({
      title: 'Success',
      message: 'Dissociate Successfully',
      type: 'success',
      duration: 2000
    })
  }

  private addGiftCards() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: ['giftCard.id', 'customer.id', 'sku.id', 'delivery.id'] })

        this.cards.push(data)
        this.$notify({
          title: 'Card Created',
          message: 'Customer successfully created',
          type: 'success',
          duration: 2000
        })
      }
      this.createDialogFormVisible = false
    })
  }
}
