





































































































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { Advertiser, Customer, Location } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'CustomerEdit',
  components: {
    CardTable: () => import('./components/cards-table.vue')
  }
})
export default class extends Vue {
  customer: Customer = new Customer({ advertiser: new Advertiser({ id: '' }) })
  customerLoading = false

  advertisers: Advertiser[] = []
  advertisersLoading = false

  registrationStatusSelect = [
    { label: 'Waiting', value: 'waiting' },
    { label: 'Verified', value: 'verified' },
    { label: 'Verification failed', value: 'verification_failed' }
  ]

  async created() {
    if (!this.isCreate) await this.getCustomer()
    await this.getAdvertisers()
  }

  get isCreate() {
    return this.$route.name === 'CustomerCreate'
  }

  private async getCustomer() {
    this.customerLoading = true
    const { data } = await Customer.includes(['advertiser', 'location', 'program']).find(this.$route.params.id)

    this.customer = data
    if (!this.customer.location) this.customer.location = new Location()
    this.customerLoading = false
  }

  private async getAdvertisers() {
    this.advertisersLoading = true
    const { data } = await Advertiser.per(999).page(1).all()

    this.advertisers = data
    if (this.isCreate) this.customer.advertiser = this.advertisers[0].dup()
    this.advertisersLoading = false
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        const data = this.customer

        await data.save({ with: ['advertiser.id', 'location'] })

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          if (this.isCreate) {
            this.$notify({
              title: 'Customer created',
              message: 'Customer successfully created',
              type: 'success',
              duration: 2000
            })
            this.$router.replace({ name: 'CustomerEdit', params: { id: data.id as string } }).catch(err => err)
          } else {
            this.$notify({
              title: 'Customer updated',
              message: 'Customer successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        }
      }
    })
  }

  private handleSelectAdvertiser(id: string) {
    const advertiser: any = this.advertisers.find((e: any) => e.id === id)
    this.customer.advertiser = advertiser?.dup()
  }

  private printErrors(errors: any) {
    const e: (IValidationError<Customer> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }
}
