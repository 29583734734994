

























































































































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { exportJson2Excel } from '@/utils/excel'
import { formatJson } from '@/utils'
import Pagination from '@/components/Pagination/index.vue'

import { Advertiser, Buyer } from '@/models/index'

@Component({
  name: 'AdvertisersTable',
  components: {
    Pagination
  }
})
export default class extends Vue {
  private tableKey = 0
  private list: Advertiser[] = []
  private total = 0
  private listLoading = true
  private listQuery = {
    page: 1,
    limit: 20,
    importance: undefined,
    email: undefined,
    type: undefined,
    sort: '+id'
  }

  private buyerList: any[] = []

  private dialogFormVisible = false
  private dialogStatus = ''
  private textMap = {
    update: 'Edit',
    create: 'Create'
  }

  private rules = {
    type: [{ required: true, message: 'type is required', trigger: 'change' }],
    timestamp: [{ required: true, message: 'timestamp is required', trigger: 'change' }],
    title: [{ required: true, message: 'title is required', trigger: 'blur' }]
  }

  private downloadLoading = false
  private tempData = new Advertiser({ buyer: new Buyer({ id: '' }) })

  created() {
    this.getList()
    this.getBuyerList()
  }

  private async getList() {
    this.listLoading = true
    const { data, meta } =
      await Advertiser
        .includes('buyer')
        .stats({ total: 'count' })
        .page(this.listQuery.page)
        .per(this.listQuery.limit)
        .all()

    this.list = data
    this.total = meta.stats.total.count
    this.listLoading = false
  }

  private async getBuyerList() {
    const { data } = await Buyer.all()

    this.buyerList = data
  }

  private handleFilter() {
    this.listQuery.page = 1
    this.getList()
  }

  private handleModifyStatus(row: any, status: string) {
    this.$message({
      message: '操作成功',
      type: 'success'
    })
    row.status = status
  }

  private sortChange(data: any) {
    const { prop, order } = data
    if (prop === 'id') {
      this.sortByID(order)
    }
  }

  private sortByID(order: string) {
    if (order === 'ascending') {
      this.listQuery.sort = '+id'
    } else {
      this.listQuery.sort = '-id'
    }
    this.handleFilter()
  }

  private getSortClass(key: string) {
    const sort = this.listQuery.sort
    return sort === `+${key}` ? 'ascending' : 'descending'
  }

  private resetTempData() {
    this.tempData = new Advertiser({
      buyer: this.buyerList[0].dup()
    })
  }

  private handleCreate() {
    this.resetTempData()
    this.dialogStatus = 'create'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async createData() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: 'buyer.id' })

        this.list.unshift(data)
        this.total += 1
        this.dialogFormVisible = false
        this.$notify({
          title: 'Advertiser created',
          message: 'Advertiser successfully created',
          type: 'success',
          duration: 2000
        })
      }
    })
  }

  private handleUpdate(row: any) {
    this.tempData = row
    this.dialogStatus = 'update'
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private updateData() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: 'buyer.id' })

        const index = this.list.findIndex(v => v.id === data.id)
        this.list.splice(index, 1, data)
        this.dialogFormVisible = false
        this.$notify({
          title: 'Advertiser Updated',
          message: 'Advertiser successfully updated',
          type: 'success',
          duration: 2000
        })
      }
    })
  }

  private async handleDelete(data: Advertiser, index: number) {
    await data.destroy()

    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
    this.list.splice(index, 1)
    this.total -= 1
  }

  private handleSelectBuyer(buyerId: any) {
    this.tempData.buyer = this.buyerList.find(e => e.id === buyerId)
  }

  private handleDownload() {
    this.downloadLoading = true
    const tHeader = ['timestamp', 'title', 'type', 'importance', 'status']
    const filterVal = ['timestamp', 'title', 'type', 'importance', 'status']
    const data = formatJson(filterVal, this.list)
    exportJson2Excel(tHeader, data, 'table-list')
    this.downloadLoading = false
  }
}
