











































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Card } from '@/models/index'
import { Form, Popover } from 'element-ui'

@Component({
  name: 'RelatedCardstable'
})
export default class extends Vue {
  @Prop({ required: true }) private cards!: Card[]

  private tempCard: Card = new Card({ usageStatus: '' })
  private tableKey = 0
  private updateCardUsageStatus = false
  private rules = {
    usageStatus: [{ required: true, message: 'État d\'utilisation requis', trigger: 'blur' }]
  }

  private usageStatusList = [
    {
      value: 'unused',
      name: 'Inutilisé'
    },
    {
      value: 'partially_used',
      name: 'Partiellement utilisé'
    },
    {
      value: 'used',
      name: 'Utilisé'
    },
    {
      value: 'archived',
      name: 'Archivé'
    }
  ]

  setTempCard(card: Card) {
    this.tempCard = card.dup()
    if (!this.tempCard?.usageStatus) this.tempCard.usageStatus = this.usageStatusList[0].value
  }

  private updateCustomerProgram(formIndex: number) {
    (this.$refs[`usageStatusForm${formIndex}`] as Form).validate(async(valid) => {
      this.updateCardUsageStatus = true
      if (valid) {
        const data = this.tempCard

        try {
          await data.save()

          this.$emit('update', { formIndex, data })
          this.$notify({
            title: 'Card usage status updated',
            message: `Card usage status is now : ${this.tempCard.usageStatus}`,
            type: 'success',
            duration: 2000
          })
        } catch (err) {
          this.updateCardUsageStatus = false
        }
      }
      this.updateCardUsageStatus = false
      this.tableKey++
      (this.$refs[`popover_${formIndex}`] as Popover).$destroy()
    })
  }
}
