var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('div',{staticClass:"filter-container"},[_c('el-input',{staticClass:"filter-item",staticStyle:{"width":"200px"},attrs:{"placeholder":"E-mail"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleFilter.apply(null, arguments)}},model:{value:(_vm.listQuery.email),callback:function ($$v) {_vm.$set(_vm.listQuery, "email", $$v)},expression:"listQuery.email"}}),_c('el-button',{directives:[{name:"waves",rawName:"v-waves"}],staticClass:"filter-item",attrs:{"type":"primary","icon":"el-icon-search"},on:{"click":_vm.handleFilter}},[_vm._v(" "+_vm._s(_vm.$t('table.search'))+" ")]),_c('el-button',{staticClass:"filter-item",staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","icon":"el-icon-edit"},on:{"click":function($event){return _vm.$router.push({name: 'CategoryCreate'})}}},[_vm._v(" "+_vm._s(_vm.$t('table.add'))+" ")])],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],key:_vm.tableKey,staticStyle:{"width":"100%"},attrs:{"data":_vm.list,"fit":"","highlight-current-row":""}},[_c('el-table-column',{attrs:{"label":"Category image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-image',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":row.logo}})]}}])}),_c('el-table-column',{attrs:{"label":"Name","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.name))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('table.actions'),"align":"center","class-name":"fixed-width"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-button',{attrs:{"type":"primary","size":"mini"},on:{"click":function($event){return _vm.$router.push({name: 'CategoryEdit', params: {id: row.id}})}}},[_vm._v(" "+_vm._s(_vm.$t('table.edit'))+" ")]),(row.status !== 'deleted')?_c('el-button',{attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.handleDelete(row, $index)}}},[_vm._v(" "+_vm._s(_vm.$t('table.delete'))+" ")]):_vm._e()]}}])})],1),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"page":_vm.listQuery.page,"limit":_vm.listQuery.limit},on:{"update:page":function($event){return _vm.$set(_vm.listQuery, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listQuery, "limit", $event)},"pagination":_vm.getList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }