



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Category, GiftCard, GiftCardCategory } from '@/models/index'
import { Form } from 'element-ui'

@Component({
  name: 'GiftCardsTable'
})
export default class extends Vue {
  @Prop({ required: true }) private parentCategory!: Category

  private giftCardCategoryList: GiftCardCategory[] = []
  private giftCardCategoryListLoading = false

  private giftCardList: GiftCard[] = []
  private giftCardListLoading = false

  private tempData = new GiftCardCategory({ category: new Category({ id: this.parentCategory.id }) })
  private dialogFormVisible = false

  private async getGiftCardCategoryList() {
    this.giftCardCategoryListLoading = true
    const { data } = await GiftCardCategory
      .where({ categoryId: this.parentCategory.id })
      .includes('giftCard')
      .all()

    this.giftCardCategoryList = data
    this.giftCardCategoryListLoading = false
  }

  private async getGiftCardList() {
    this.giftCardListLoading = true
    const { data } = await GiftCard.per(999).page(1).all()

    this.giftCardList = data
    this.giftCardListLoading = false
  }

  private resetTempData() {
    this.tempData = new GiftCardCategory({
      category: this.parentCategory.dup(),
      giftCard: this.giftCardList[0].dup()
    })
  }

  private handleAdd() {
    this.resetTempData()
    this.dialogFormVisible = true
    this.$nextTick(() => {
      (this.$refs.dataForm as Form).clearValidate()
    })
  }

  private async handleDelete(data: GiftCardCategory, index: number) {
    await data.destroy()

    this.giftCardCategoryList.splice(index, 1)
    this.$notify({
      title: 'Success',
      message: 'Delete Successfully',
      type: 'success',
      duration: 2000
    })
  }

  private addGiftCards() {
    (this.$refs.dataForm as Form).validate(async(valid) => {
      if (valid) {
        const data = this.tempData

        await data.save({ with: ['giftCard.id', 'category.id'] })

        this.giftCardCategoryList.push(data)
        this.$notify({
          title: 'Customer Created',
          message: 'Customer successfully created',
          type: 'success',
          duration: 2000
        })
      }
      this.dialogFormVisible = false
    })
  }

  created() {
    this.getGiftCardCategoryList()
    this.getGiftCardList()
  }
}
