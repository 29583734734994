

































































































































import { Component, Vue } from 'vue-property-decorator'
import { CustomerOrder, Card, CardDistribution } from '@/models/index'
import { ITagView, TagsViewModule } from '@/store/modules/tags-view'

@Component({
  name: 'CustomerOrdersEdit',
  components: {
    RelatedCardsTable: () => import('./components/related-cards-table.vue'),
    CardDistributionTooltip: () => import('@/components/CardDistributionTooltip/index.vue'),
    CardDistributionEditDrawer: () => import('@/components/CardDistributionEditDrawer/index.vue')
  }
})
export default class extends Vue {
  private customerOrder: CustomerOrder = new CustomerOrder()
  private customerOrderLoading = true

  private cardDistributionEditDrawerVisible = false

  created() {
    this.getOrder()
  }

  get orderDetails() {
    return [
      {
        label: 'Valeur faciale',
        value: `${this.customerOrder.amount?.toFixed(2) || '-'} €`
      },
      {
        label: 'Montant payé',
        value: `${this.customerOrder.discountedAmount?.toFixed(2) || '-'} €`
      }
    ]
  }

  private async getOrder() {
    this.customerOrderLoading = true
    const { data } = await CustomerOrder
      .includes([{ cardDistributions: 'sku'}, { giftCard: 'skus' }, 'customer', { cards: 'sku' }])
      .find(this.$route.params.id)

    this.customerOrder = data
    this.updateTagView(data)
    this.customerOrderLoading = false
  }

  handleRelatedCardsUpdate(event: any) {
    this.customerOrder.cards.splice(event.formIndex, 1, event.data)
  }

  mounted() {
    this.updateTagView()
  }

  updateTagView(order?: CustomerOrder) {
    const matchTagView: any = TagsViewModule.visitedViews.filter(view => view.params?.id === this.$route.params.id)

    if (matchTagView.length === 1) {
      matchTagView[0].title = `Customer order ${order?.number || 'loading...'}`
      matchTagView[0].meta.title = `Customer order ${order?.number || 'loading...'}`
      TagsViewModule.updateVisitedView({ ...matchTagView[0] as ITagView })
    }
  }

  private async update() {
    const data = this.customerOrder

    await data.save({ with: { cardDistributions: 'sku.id' }})

    this.$notify({
      title: 'CustomerOrder updated',
      message: 'CustomerOrder successfully updated',
      type: 'success',
      duration: 2000
    })

    this.getOrder()
  }

  private async dissociateCard(card: Card) {
    const index = this.customerOrder.cards.findIndex(e => e.id === card.id)

    if (index >= 0) {
      this.customerOrder.cards[index].isMarkedForDisassociation = true
      await this.customerOrder.save({ with: 'cards.id' })

      this.customerOrder.cards.splice(index, 1)
    }
  }

  private addCardDistribution() {
    const sku = this.customerOrder.giftCard.skus[0].dup()
    this.customerOrder.cardDistributions.push(new CardDistribution({ quantity: 1, amount: 50, sku: sku }))
  }

  private handleSkuSelect(skuId: string, cdIndex: number) {
    const selected = this.customerOrder.giftCard.skus.find(e => e.id === skuId)
    if (selected) {
      this.customerOrder.cardDistributions[cdIndex].sku = selected.dup()
    }
  }

  private async deleteCardDistribution(cd: CardDistribution, index: number) {
    if (cd.isPersisted) {
      await cd.destroy()

      this.$notify({
        title: 'Success',
        message: 'Delete Successfully',
        type: 'success',
        duration: 2000
      })
    }
    this.customerOrder.cardDistributions.splice(index, 1)
  }
}
