






























import { Component, Vue } from 'vue-property-decorator'
import { Form } from 'element-ui'
import { Category } from '@/models/index'
import { IValidationError } from 'spraypaint/lib-esm/validation-errors'

@Component({
  name: 'CategoryEdit',
  components: {
    GiftCardsTable: () => import('./components/gift-cards-table.vue'),
    DirectUpload: () => import('@/components/DirectUpload/index.vue')
  }
})
export default class extends Vue {
  private category: Category = new Category()
  private categoryLoading = false
  private rules = {
    name: [{ required: true, message: 'Name is required', trigger: 'blur' }]
  }

  created() {
    if (!this.isCreate) this.getCategory()
  }

  get isCreate() {
    return this.$route.name === 'CategoryCreate'
  }

  private uploadCallback(file: any) {
    this.category.logo = file.blob.signed_id
  }

  private async getCategory() {
    this.categoryLoading = true
    const { data } = await Category
      .selectExtra(['logoBlob'])
      .find(this.$route.params.id)

    this.category = data
    this.categoryLoading = false
  }

  private async createOrUpdate() {
    (this.$refs.dataForm as Form).validate(async(valid: any) => {
      if (valid) {
        const data = this.category

        await data.save()

        if (Object.keys(data.errors).length > 0) {
          this.printErrors(data.errors)
        } else {
          if (this.isCreate) {
            this.$notify({
              title: 'Category created',
              message: 'Category successfully created',
              type: 'success',
              duration: 2000
            })
            this.$router.replace({ name: 'CategoryEdit', params: { id: data.id as string } }).catch(err => err)
          } else {
            this.$notify({
              title: 'Category updated',
              message: 'Category successfully updated',
              type: 'success',
              duration: 2000
            })
          }
        }
      }
    })
  }

  private printErrors(errors: any) {
    const e: (IValidationError<Category> | undefined)[] = Object.values(errors)
    for (const error of e) {
      if (error) {
        this.$notify({
          title: error.title,
          message: error.fullMessage,
          type: 'error',
          duration: 5000
        })
      }
    }
  }
}
